@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "sk-modernist";
  src: url("assets/fonts/Sk-Modernist-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sk-modernist";
  src: url("assets/fonts/Sk-Modernist-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "sk-modernist-mono";
  src: url("assets/fonts/Sk-Modernist-Mono.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "sk-modernist";
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}


.bg-img {
  background-image: url("/src/assets/avercast/experience-bg.png");
  background-clip: border-box;
  background-size: cover;

}

.hide-bg {
  background: none;
}
